import BaseIcon from '../../../assets/icons/icon-base.svg';
import BitcoinIcon from '../../../assets/icons/icon-bitcoin.svg';
import SolanaIcon from '../../../assets/icons/icon-solana.svg';
import EthereumIcon from '../../../assets/icons/icon-ethereum.svg';
import PolygonIcon from '../../../assets/icons/icon-polygon.svg';
import ClipboardButton from '../ClipboardButton/ClipboardButton';
import TelegramText from '../Text/TelegramText';
import './CryptoInfo.scss';

const CryptoInfo = () => {
    function copyToClipboard() {
        console.log('copy to clipboard');
    }

    return (
        <div className="crypto-info">
            <div className="crypto-box">
                <div className="crypto-box__img"><img src={EthereumIcon} alt="EthereumIcon" /></div>
                <div className="crypto-box__text">
                    <TelegramText className={'telegramSubtitle'}>Ethereum</TelegramText>
                    <TelegramText className={'white-opacity'}>0x8CEe92c96AED2f9A1c9706AFe7365347b425514a</TelegramText>
                </div>
                <div className="crypto-box__btn">
                    <ClipboardButton onButtonClick={copyToClipboard}></ClipboardButton>
                </div>
            </div>
            <div className="crypto-box">
                <div className="crypto-box__img"><img src={BitcoinIcon} alt="BitcoinIcon" /></div>
                <div className="crypto-box__text">
                    <TelegramText className={'telegramSubtitle'}>Bitcoin</TelegramText>
                    <TelegramText className={'white-opacity'}>bc1qh4supqae9zklyc7fd4jr48r260af4t9r6wj5h4</TelegramText>
                </div>
                <div className="crypto-box__btn">
                    <ClipboardButton onButtonClick={copyToClipboard}></ClipboardButton>
                </div>
            </div>
            <div className="crypto-box">
                <div className="crypto-box__img"><img src={SolanaIcon} alt="SolanaIcon" /></div>
                <div className="crypto-box__text">
                    <TelegramText className={'telegramSubtitle'}>Solana</TelegramText>
                    <TelegramText className={'white-opacity'}>CUm7c4rFdZfu4Ec7PeNvPf59JStjcnP87vFhUkPpCSM2</TelegramText>
                </div>
                <div className="crypto-box__btn">
                    <ClipboardButton onButtonClick={copyToClipboard}></ClipboardButton>
                </div>
            </div>
            <div className="crypto-box">
                <div className="crypto-box__img"><img src={PolygonIcon} alt="PolygonIcon" /></div>
                <div className="crypto-box__text">
                    <TelegramText className={'telegramSubtitle'}>Polygon</TelegramText>
                    <TelegramText className={'white-opacity'}>0x8CEe92c96AED2f9A1c9706AFe7365347b425514a</TelegramText>
                </div>
                <div className="crypto-box__btn">
                    <ClipboardButton onButtonClick={copyToClipboard}></ClipboardButton>
                </div>
            </div>
            <div className="crypto-box">
                <div className="crypto-box__img"><img src={BaseIcon} alt="BaseIcon" /></div>
                <div className="crypto-box__text">
                    <TelegramText className={'telegramSubtitle'}>Base</TelegramText>
                    <TelegramText className={'white-opacity'}>0x8CEe92c96AED2f9A1c9706AFe7365347b425514a</TelegramText>
                </div>
                <div className="crypto-box__btn">
                    <ClipboardButton onButtonClick={copyToClipboard}></ClipboardButton>
                </div>
            </div>
        </div>
    );
};

export default CryptoInfo;
