import './App.css';
import { Route, Routes, Navigate, useParams } from "react-router-dom";

import {
    PATH_EARNED,
    PATH_MAIN, PATH_REDEEM, PATH_SPLASH, PATH_TASKS, PATH_CLAIM, PATH_COLLECTIBLES,
    PATH_ABOUT,
    PATH_CHARTS,
    PATH_CREATE
} from "./constants/Paths";

import React, { useEffect, useState } from "react";
import { useTelegram } from "./hooks/useTelegram";

import Main from "./screens/main/MainScreen";
import About from "./screens/about/About";
import TasksPage from "./screens/tasks/Tasks-page";
import Earnings from "./screens/earnings/Earnings";
import Claim from "./screens/Claim/Claim";
import Redeem from "./screens/redeem/Redeem";
import CollectiblesPage from "./screens/collectibles/collectibles";
import LeaderBoard from './screens/LeaderBoard/LeaderBoard';
import CreatePage from './screens/create/CreatePage';

function App() {
    const { webApp } = useTelegram();
    useEffect(() => {
        return () => {
            localStorage.setItem('openState', JSON.stringify(false))
        }
    }, []);

    // const [opacity, setOpacity] = useState(1);
    // const transitions = useTransition(1, {
    //     key: 1,
    //     from: { opacity: 1 },
    //     enter: { opacity: 1 },
    //     leave: { opacity: 0 },
    //     config: { duration: 2000 },
    //     exitBeforeEnter: true,
    //     onRest: ( item) => {
    //         setOpacity(0);
    //     },
    // })

    useEffect(() => {
        webApp.ready();
        webApp.expand();
    }, [webApp]);

    return (
        <div className="App">
            {/*<div className="App" style={{cursor: 'url(paddle.png), auto'}}>*/}
            {/*{transitions((style, i) => (*/}
            {/*    <animated.div style={{...style, position: 'absolute', top: 0, left: 0, opacity}}><Splash></Splash></animated.div>))}*/}
            <Routes>
                <Route index element={<Main />} />
                <Route path="/:projectName" element={<Main />} />
                <Route path={PATH_MAIN} element={<Main />} />
                <Route path={PATH_ABOUT} element={<About />} />
                <Route path={PATH_TASKS} element={<TasksPage />} />
                <Route path={PATH_CHARTS} element={<LeaderBoard />} />
                <Route path={PATH_CREATE} element={<CreatePage />} />
                <Route path={PATH_EARNED} element={<Earnings />} />
                <Route path={PATH_REDEEM} element={<Redeem />} />
                <Route path={PATH_CLAIM} element={<Claim />} />
                <Route path={PATH_COLLECTIBLES} element={<CollectiblesPage />} />
                {/*<Route path={PATH_FUNCTIONS_BASIC} element={<BasicFunctionsScreen/>}/>*/}
                {/*<Route path={PATH_FUNCTIONS_ALERTS} element={<AlertFunctionsScreen/>}/>*/}
                {/*<Route path={PATH_FUNCTIONS_EVENTS} element={<EventFunctionsScreen/>}/>*/}
                {/*<Route path={PATH_FUNCTIONS_BOT} element={<BotFunctionsScreen/>}/>*/}
                {/*<Route path={PATH_FUNCTIONS_LINKS} element={<LinkFunctionsScreen/>}/>*/}
                {/*<Route path={PATH_FUNCTIONS_THEME} element={<ThemeFunctionsScreen/>}/>*/}
                {/*<Route path={PATH_FUNCTIONS_QR} element={<QrFunctionsScreen/>}/>*/}
                {/*<Route path={PATH_BUTTONS_MAIN} element={<MainButtonScreen/>}/>*/}
                {/*<Route path={PATH_BUTTONS_BACK} element={<BackButtonScreen/>}/>*/}
                {/*<Route path={PATH_HAPTIC} element={<HapticFeedbackScreen/>}/>*/}
                {/*<Route path={PATH_SERVER} element={<ServerScreen/>}/>*/}
            </Routes>
        </div>
    );
}

export default App;
