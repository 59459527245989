import TelegramButton from "../Button/TelegramButton";
import './ClipboardButton.scss';
import { ReactComponent as CopyIcon } from '../../../assets/icons/icon-copy.svg';
import TelegramText from "../Text/TelegramText";

const ClipboardButton = (props) => {
    return (
        <TelegramButton onClick={props.onButtonClick} {...props} className={'clipboard-button ' + props.className}>
            <CopyIcon className={'ClipboardButton__icon'} />
            <TelegramText className={'ClipboardButton__text'}>COPY</TelegramText>
        </TelegramButton>
    );
};

export default ClipboardButton;

