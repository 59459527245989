import React, { useEffect, useRef } from 'react';
import { ReactComponent as PlayFilled } from '../../assets/icons/play-filled.svg';
import { ReactComponent as StopFilled } from '../../assets/icons/stop-filled.svg';
import { ReactComponent as VoteIcon } from '../../assets/icons/vote.svg';

interface LeaderBoardRowProps {
    trackUrl: string;
    title: string;
    reward: string;
    votes: string;
    onVote?: () => void;
    onPlayPause?: () => void;
    isPlaying?: boolean;
    onProgress?: (progress: number) => void;
    volume?: number;
    isPlayer?: boolean;
    index: number;
    logoImage: string;
    logoText: string;
}

const LeaderBoardRow: React.FC<LeaderBoardRowProps> = ({ trackUrl, title, reward, votes, onVote, onPlayPause, isPlaying, onProgress, volume = 1, isPlayer = false, index, logoImage, logoText }) => {
    const togglePlayPause = () => {
        if (onPlayPause) {
            onPlayPause();
        }
    };

    return (
        <div className={`leaderboard-row ${isPlayer ? 'player' : ''} ${isPlaying ? 'playing' : ''}`}>
            <div className="leaderboard-index" onClick={togglePlayPause}>
                {isPlaying ? <StopFilled /> : <PlayFilled />}
                {!isPlayer && <div className="track-number">{index + 1}</div>}
            </div>
            {!isPlayer && (
                <div className="leaderboard-logo">
                    <img src={logoImage} alt={logoText} />
                    <div>{logoText}</div>
                </div>
            )}
            <div className="leaderboard-info">
                <div className="leaderboard-title">{title}</div>
                {!isPlayer && <div className="leaderboard-reward">{reward}</div>}
            </div>
            <div className="leaderboard-vote" onClick={onVote}>
                <VoteIcon />
                {!isPlayer && <div className="leaderboard-votes">{votes}</div>}
            </div>
        </div>
    );
};

export default LeaderBoardRow;