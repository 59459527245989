import React, { useEffect, useState } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
// import {useTelegram} from "../../hooks/useTelegram";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { claimPoints, getTasks, setInteraction } from "../../logic/server/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTelegram } from "../../hooks/useTelegram";
import TelegramButton from '../../components/kit/Button/TelegramButton';
import TelegramText from '../../components/kit/Text/TelegramText';

import WelcomeImage from '../../assets/welcome-image2.png';
import { ReactComponent as PPIcon } from "../../assets/icons/pp-small.svg";
import { ReactComponent as ClaimIcon } from "../../assets/icons/claim.svg";
import { ReactComponent as CheckIcon } from "../../assets/check-circle.svg";
import { ReactComponent as ArrowIcon } from "../../assets/arrow-right.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/icons/youtube.svg";
import { ReactComponent as FollowIcon } from "../../assets/icons/follow.svg";
import { ReactComponent as InviteIcon } from "../../assets/icons/invite.svg";

export const TaskType = {
    visited: 'interaction-tracker.link-visited',
    referral: 'referral-number',
    click: 'click',
};

const TasksPage = () => {
    const { webApp, user } = useTelegram();
    const [tasks, setTasks] = useState([]);
    // const [tasksFollowPartner, setPartnersTasks] = useState([]);
    // const [tasksFollowChannel, setChannelTasks] = useState([]);
    const [linkOpened, setLinkOpened] = useState([]);
    const [taskClaimed, setTaskClaimed] = useState([]);

    useEffect(() => {
        getTasksRequest();
    }, []);

    useEffect(() => {
        getTasksRequest();
    }, [linkOpened, taskClaimed]);

    async function getTasksRequest() {
        const resp = await getTasks();
        console.log(resp);

        // const followChannel = resp?.tasks?.filter(item => item?.conditions[0]?.type === 'interaction-tracker.link-visited');
        // setChannelTasks(followChannel?.length ? followChannel : []);
        setTasks(resp?.tasks);
        // console.log(resp);
    }

    async function handleInteraction(task) {
        if (task?.conditions[0]?.type === TaskType.referral) {
            window.location.assign('/earned');
            return;
        }
        if (task?.conditions[0].data?.link && (!task?.completion.claimable && task?.completion.status !== 'completed')) {
            // console.log(task);
            const res = await setInteraction(task?.conditions[0].data?.link);
            // window.open(task?.conditions[0].data?.link, '_blank');
            // window.location.assign(task?.conditions[0].data?.link);
            webApp.openLink(task?.conditions[0].data?.link);

            // window.location.href = task?.conditions[0].data?.link;
            if (res?.status) {
                setLinkOpened([{ id: task.id, status: true }]);
            }
        } else if (task?.completion.claimable) {
            handleClaim(task);
            toast.success(`You claimed ${task?.reward?.data?.value} coins successfully!`);

        }
    }

    async function handleClaim(task) {
        const res = await claimPoints(localStorage.getItem('token'), task.id);
        if (res?.status) {
            setTaskClaimed([{ id: task.id, status: true }]);
        }
    }

    function claimAllRewards() {
        tasks.map(task => {
            if (task?.completion.claimable) {
                handleClaim(task);
            }
        });
    }

    return (
        <TelegramScreen
            className={``} id='gesuredZone'>
            <div className='main-container'>
                <div className='welcome-holder'>
                    <div className="columns-holder">
                        <div className="column">
                            <div className="welcome">
                                <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>{tasks?.filter(task => !task.completion.claimable).length} {tasks?.length > 1 ? 'tasks' : 'task'} available</span></TelegramText>
                                <TelegramButton className={'block'} onClick={claimAllRewards}><ClaimIcon /> Claim all rewards</TelegramButton>
                                <div className="decor">
                                    <img src={WelcomeImage} alt={'Welcome'} />
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className='cards-container-dim'>
                                <div className={'cards-container--top'}>
                                    <div className={'cards__container'}>
                                        {/* {tasks.map((task) => (
                                            <div className={'new-card__task'}
                                                key={task}
                                            >

                                                <div className={'card__column-to-row'}>
                                                    <p className={'card__task--text'}>{task?.title}</p>
                                                    <p className={'small-card-task-text'}><PPIcon></PPIcon> {task?.reward?.data?.value} PP</p>
                                                </div>
                                                <div className={'claim-row'}>
                                                    <ClaimIcon />
                                                </div>
                                            </div>
                                        ))} */}
                                        {tasks?.filter(task => !task.completion.claimable).map((task) => (
                                            <div className={`new-card__task ${task?.completion.status === 'completed' ? 'disabled--bright' : ''}`}
                                                onClick={() => handleInteraction(task)}>
                                                <div className={'card__column-to-row'}>
                                                    <div className={'card__task--text'}>
                                                        <div className="card__task--icon">
                                                            {task?.conditions[0]?.type === TaskType.visited && <FollowIcon />}
                                                            {task?.conditions[0]?.type === TaskType.referral && <InviteIcon />}
                                                            {task?.conditions[0]?.type === TaskType.click && <YoutubeIcon />}
                                                        </div>
                                                        <div className="card__task--holder">
                                                            {task?.title}
                                                            <p className={'small-card-task-text'}><PPIcon></PPIcon> {task?.reward?.data?.value} PP</p>
                                                        </div>
                                                    </div>
                                                    {(!task?.completion.claimable && task?.completion.status !== 'completed') && <div className={'claim-row'}>
                                                        <p className={'small-card-task-text'}>APPLY</p>
                                                        <div className="small-card-task-icon">
                                                            <ArrowIcon />
                                                        </div>
                                                    </div>}
                                                    {(task?.completion.status === 'completed') && <div className={'claim-row'}>
                                                        <p className={'small-card-task-text'}>CLAIMED</p>
                                                        <div className="small-card-task-icon">
                                                            <CheckIcon />
                                                        </div>
                                                    </div>}
                                                    {task?.completion.claimable && <div className={'claim-row'}>
                                                        <p className={'small-card-task-text'}>CLAIM</p>
                                                        <div className="small-card-task-icon">
                                                            <ClaimIcon />
                                                        </div>
                                                    </div>}
                                                </div>


                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MainNavigation currentPage={'tasks'} />
            </div>
            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>)

}
export default TasksPage;
