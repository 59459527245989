import React from 'react';
import { ReactComponent as CollectibleIcon } from '../../../assets/icons/collectible.svg';
import { ReactComponent as ChevronRight } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as AboutIcon } from '../../../assets/icons/about.svg';
import { ReactComponent as PlayIcon } from '../../../assets/game-page.svg';
import { ReactComponent as TasksIcon } from '../../../assets/tasks-page.svg';
import { ReactComponent as FriendsIcon } from '../../../assets/friends-page.svg';
import { ReactComponent as RedeemIcon } from '../../../assets/redeem-page.svg';
import { ReactComponent as BagIcon } from '../../../assets/bag-icon.svg';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import { PATH_CLAIM, PATH_COLLECTIBLES, PATH_EARNED, PATH_MAIN, PATH_ABOUT, PATH_TASKS, PATH_CHARTS } from "../../../constants/Paths";
import { useNavigate } from "react-router-dom";
import TelegramText from '../../../components/kit/Text/TelegramText';
import TelegramButton from '../../../components/kit/Button/TelegramButton';
import DecorImage from '../../../assets/backgrounds/main-dropdown-bg.jpeg';

const MainNavigationDropdown = ({ currentPage, onNavigate }) => {
    const navigate = useNavigate();

    const handleNavigate = (path) => {
        onNavigate(path);
    };

    return (
        <div className='main-navigation-dropdown'>
            <div className="main-navigation-dropdown__holder">
                <TelegramText className={'telegramSubTitle'}>Connect wallet to</TelegramText>
                <div className="btns-holder">
                    <TelegramButton className={`outlined`}>Phantom</TelegramButton>
                    <TelegramButton className={`outlined`}>Solflare</TelegramButton>
                </div>
                <div className='main-navigation-dropdown-container'>
                    <div className={`main-navigation-dropdown__cards ${currentPage === 'main' ? 'main-navigation-dropdown__cards--active' : ''}`}
                        onClick={() => handleNavigate(PATH_MAIN)}>
                        <div className={`main-navigation-dropdown__text--holder forms__bottom-icon--day-mode`}>
                            <PlayIcon className={'main-navigation-dropdown__icon'} />
                            <div className='main-navigation-dropdown__text'>Beats game</div>
                        </div>
                        <ChevronRight className={'main-navigation-dropdown__chevron'} />
                    </div>
                    <div className={`main-navigation-dropdown__cards ${currentPage === 'tasks' ? 'main-navigation-dropdown__cards--active' : ''} `}
                        onClick={() => handleNavigate(PATH_TASKS)}>
                        <div className='main-navigation-dropdown__text--holder'>
                            <TasksIcon className={'main-navigation-dropdown__icon'} />
                            <div className='main-navigation-dropdown__text'>Today's tasks</div>
                        </div>
                        <ChevronRight className={'main-navigation-dropdown__chevron'} />
                    </div>
                    <div className={`main-navigation-dropdown__cards ${currentPage === 'leaderboard' ? 'main-navigation-dropdown__cards--active' : ''}  `}
                        onClick={() => handleNavigate(PATH_CHARTS)}>
                        <div className='main-navigation-dropdown__text--holder'>
                            <BagIcon className={'main-navigation-dropdown__icon'} />
                            <div className='main-navigation-dropdown__text'>Top chart</div>
                        </div>
                        <ChevronRight className={'main-navigation-dropdown__chevron'} />
                    </div>
                    <div className={`main-navigation-dropdown__cards ${currentPage === 'earnings' ? 'main-navigation-dropdown__cards--active' : ''} `}
                        onClick={() => handleNavigate(PATH_EARNED)}>
                        <div className='main-navigation-dropdown__text--holder'>
                            <FriendsIcon className={'main-navigation-dropdown__icon'} />
                            <div className='main-navigation-dropdown__text'>Invite friends</div>
                        </div>
                        <ChevronRight className={'main-navigation-dropdown__chevron'} />
                    </div>
                    <div className={`main-navigation-dropdown__cards ${currentPage === 'collectibles' ? 'main-navigation-dropdown__cards--active' : ''}  `}
                        onClick={() => handleNavigate(PATH_COLLECTIBLES)}>
                        <div className='main-navigation-dropdown__text--holder'>
                            <CollectibleIcon className={'main-navigation-dropdown__icon'} />
                            <div className='main-navigation-dropdown__text'>Collected items</div>
                        </div>
                        <ChevronRight className={'main-navigation-dropdown__chevron'} />
                    </div>
                    <div className={`main-navigation-dropdown__cards ${currentPage === 'redeem' ? 'main-navigation-dropdown__cards--active' : ''} `}
                        onClick={() => handleNavigate(PATH_CLAIM)}>
                        <div className='main-navigation-dropdown__text--holder'>
                            <RedeemIcon className={'main-navigation-dropdown__icon'} />
                            <div className='main-navigation-dropdown__text'>Power actions</div>
                        </div>
                        <ChevronRight className={'main-navigation-dropdown__chevron'} />
                    </div>
                    <div className={`main-navigation-dropdown__cards ${currentPage === 'about' ? 'main-navigation-dropdown__cards--active' : ''}`}
                        onClick={() => handleNavigate(PATH_ABOUT)}>
                        <div className={`main-navigation-dropdown__text--holder forms__bottom-icon--day-mode`}>
                            <AboutIcon className={'main-navigation-dropdown__icon'} />
                            <div className='main-navigation-dropdown__text'>About RapBitoinGame</div>
                        </div>
                        <ChevronRight className={'main-navigation-dropdown__chevron'} />
                    </div>
                </div>
            </div>
            <div className="decor">
                <img src={DecorImage} alt={'decor'} />
            </div>
        </div>
    );
};

export default MainNavigationDropdown;