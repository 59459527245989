import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { setAmount } from "./counterSlice";
import {
    getProductMetadata, getCollectibles, getCustomersWallet, getCustomerParams,
    getLoyaltyData, getPointsLimits, registerInAffiliate, flySignup as flySignupApi,
    getAllTracks
} from "../../logic/server/api";
import { useTelegram } from "../../hooks/useTelegram";
import AnimationScreen from "./AnimationScreen";
import { APP_VERSION } from "../../logic/server/Variables";

const Main = () => {
    const { projectName } = useParams();
    const location = useLocation();
    const [metadata, setMetadata] = useState(null);
    const [tracks, setTracks] = useState([]);
    const [loading, setLoading] = useState(true);
    const { webApp } = useTelegram();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [personPoints, setPersonPoints] = useState(null);
    const [sleepMode, setSleepMode] = useState(false);
    const [energy, setEnergy] = useState(5000);
    const [collectibles, setCollectibles] = useState([]);
    const [customer, setCustomer] = useState({ customer_id: null, token: null });
    const [person, setPerson] = useState({ customer_id: null, token: null });
    const isStarted = useRef(false);
    const initData = webApp.initData;
    const customer_id = new URLSearchParams(window.location.search)?.get('customer_id');
    const token = new URLSearchParams(window.location.search)?.get('token');
    const isAppOpenedSaved = useSelector(state => state.settings.isOpened);
    localStorage.setItem('openState', isAppOpenedSaved);

    useEffect(() => {
        fetchMetadata(projectName);
    }, [projectName]);

    const fetchMetadata = async (projectName) => {
        setLoading(true);
        let response;
        if (projectName) {
            response = await getProductMetadata(projectName);
        } else {
            response = await getAllTracks();
        }
            
        if (response && response.status) {
            setMetadata(response.data[0]);
            setTracks(response.data);
        }
        console.log(response.data[0].title);
        
        setMetadata(response.data[0]);
        setLoading(false);
    };
    
    const handleTrackChange = (trackTitle) => {
        const track = tracks.find(track => track.title === trackTitle);
        setMetadata(track);
    };

    useEffect(() => {
        const storedVersion = localStorage.getItem('app_version');
        if (storedVersion !== APP_VERSION) {
            localStorage.setItem('time', '');
            localStorage.setItem('sleepTime', '');
            localStorage.setItem('openState', '');
            localStorage.setItem('app_version', APP_VERSION);
        }
    }, []);

    const generateRandomString = (length) => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    useEffect(() => {
        if ((initData || customer_id || localStorage.getItem('token')) && !isStarted.current) {
            isStarted.current = true;
            if (localStorage.getItem('token')) {
                const savedCustomer = {
                    customer_id: localStorage.getItem('customer'),
                    token: localStorage.getItem('token')
                };
                setCustomer(savedCustomer);
                getCustomer(savedCustomer.customer_id, savedCustomer.token);
                getWallet(savedCustomer.token);
            } else {
                getCustomer();
                getWallet();
            }
        } else if (!initData && !customer_id && !isStarted.current) {
            isStarted.current = true;
            flySignup();
        }
    }, [initData, customer_id]);

    async function getWallet() {
        const resp = await getCustomersWallet();
        if (resp && resp.status) {
            setPersonPoints(resp?.person?.affiliate_programs[0]?.reward?.loyalty_points);
            setPerson(resp?.person?.customer);
            localStorage.setItem('time', resp.person?.customer.profile.time || '');
            localStorage.setItem('sleepTime', resp.person?.customer.profile.sleeptime || '');
            localStorage.setItem('timeOut', resp.person?.customer.profile.timeout || 'false');
        }
    }

    async function flySignup() {
        const signupBody = {
            login: generateRandomString(8),
            password: generateRandomString(12),
            profile: {}
        };
        const flySignupResp = await flySignupApi(signupBody);
        if (flySignupResp && flySignupResp.status && flySignupResp.customer_id && flySignupResp.token) {
            localStorage.setItem('token', flySignupResp.token);
            localStorage.setItem('customer', flySignupResp.customer_id);
            setCustomer({ customer_id: flySignupResp.customer_id, token: flySignupResp.token });
            getCustomer(flySignupResp.customer_id, flySignupResp.token);
            getWallet(flySignupResp.token);
        }
    }

    useEffect(() => {
        if (customer?.customer_id && customer?.token) {
            getCollectiblesItems();
        }
    }, [customer]);

    async function getCollectiblesItems() {
        const resp = await getCollectibles();
        if (resp?.status) {
            setCollectibles(resp.items);
        }
    }

    async function getAffiliatesData(customerInfo) {
        const resp2 = await getLoyaltyData(customerInfo.customer_id, customerInfo.token);
        if (resp2 && resp2?.data?.reward?.loyalty_points) {
            const points = resp2?.data?.reward?.loyalty_points;
            localStorage.setItem('code', resp2?.data?.referral_code);
            dispatch(setAmount(points));
            localStorage.setItem('points', points);
        }
        if (!resp2 || !resp2?.data?.applied) {
            const resp3 = await registerInAffiliate(customerInfo.customer_id, customerInfo.token);
            if (resp3?.referral_code) {
                localStorage.setItem('code', resp3?.referral_code);
            }
        }
    }

    async function getCustomer(customer_id, token) {
        const initDataURL = new URLSearchParams(initData);
        const referralCodeInitial = initDataURL?.get('start_param');
        const params = new URLSearchParams(window.location.search);
        let customerInfo = { customer_id: customer_id || params?.get('customer_id'), token: token || params?.get('token') };

        if (!customerInfo.customer_id || !customerInfo.token) {
            const resp = await getCustomerParams(initData, referralCodeInitial);
            if (resp?.status) {
                customerInfo = { customer_id: resp.customer?.customer_id, token: resp.customer?.token };
            }
        }

        if ((customerInfo && customerInfo.customer_id && customerInfo.token) || localStorage.getItem('token')) {
            if (customerInfo?.customer_id) {
                localStorage.setItem('token', customerInfo?.token);
                localStorage.setItem('customer', customerInfo?.customer_id);
                const resp5 = await getCollectibles();
                if (resp5.status) {
                    setCollectibles(resp5.items);
                }
            }

            if (!customerInfo.customer_id && localStorage.getItem('token')) {
                customerInfo = { customer_id: localStorage.getItem('customer'), token: localStorage.getItem('token') };
            }
            setCustomer(customerInfo);
            getAffiliatesData(customerInfo);

            if (referralCodeInitial) {
                setTimeout(() => {
                    getAffiliatesData(customerInfo);
                }, 10 * 1000);
            }

            const resp4 = await getPointsLimits(customerInfo.token);
            const remaining = resp4?.task?.completion?.limits?.reward?.per_user?.per_period?.remaining;
            if (remaining === 0) {
                setSleepMode(true);
                setEnergy(0);
            } else if (remaining) {
                setEnergy(remaining);
            }
        }
    }

    return (
        <>
            {metadata && (
                <Helmet>
                    <title>{metadata.title ? metadata.title : 'BRNZ'} Game</title>
                    <meta name="twitter:card" content="player" />
                    <meta name="twitter:site" content="@BRNZ_ai" />
                    <meta name="twitter:title" content={metadata.title} />
                    <meta name="twitter:description" content={`Enjoy the track ${metadata.title} with rewards!`} />
                    <meta name="twitter:image" content={`${window.location.origin}/logo-brnz.png`} />
                    <meta name="twitter:player" content={window.location.origin} />
                    <meta name="twitter:player:stream" content={metadata.url.startsWith('https') ? metadata.url : `${window.location.origin}${metadata.url}`} />
                    <meta name="twitter:player:stream:content_type" content="audio/mp3" />

                    <meta property="og:type" content="music.song" />
                    <meta property="og:url" content={metadata.player} />
                    <meta property="og:title" content={metadata.title} />
                    <meta property="og:description" content={metadata.description} />
                    <meta property="og:image" content={`${window.location.origin}/logo-brnz.png`} />
                    <meta property="og:audio" content={metadata.url.startsWith('https') ? metadata.url : `${window.location.origin}${metadata.url}`} />
                    <meta property="og:audio:type" content="audio/mp3" />
                </Helmet>
            )}
            {loading ? (
                <div>Loading...</div>
            ) : (
                <AnimationScreen tracks={tracks} collectibles={collectibles} person={person} personPoints={personPoints} setPersonPoints={setPersonPoints} handleTrackChange={handleTrackChange} />
            )}
        </>
    );
};

export default Main;