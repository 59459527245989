
export const PATH_SPLASH = "/splash"
export const PATH_ABOUT = "/about"
export const PATH_MAIN = "/main"
export const PATH_TASKS = "/tasks"
export const PATH_CHARTS = "/charts"
export const PATH_CREATE = "/create"
export const PATH_COLLECTIBLES = "/items"
export const PATH_EARNED = "/earned"
export const PATH_REDEEM = "/redeem"
export const PATH_CLAIM = '/claim';



// default TG app (examples)
export const PATH_DATA = "/data"
export const PATH_EVENTS = "/events"
export const PATH_FUNCTIONS = "/functions"
export const PATH_BUTTONS_MAIN = "/buttons/main"
export const PATH_BUTTONS_BACK = "/buttons/back"
export const PATH_HAPTIC = "/haptic"
export const PATH_SERVER = "/server"

export const PATH_FUNCTIONS_BASIC = `${PATH_FUNCTIONS}/basic`
export const PATH_FUNCTIONS_ALERTS = `${PATH_FUNCTIONS}/alerts`
export const PATH_FUNCTIONS_EVENTS = `${PATH_FUNCTIONS}/events`
export const PATH_FUNCTIONS_BOT = `${PATH_FUNCTIONS}/bot`
export const PATH_FUNCTIONS_THEME = `${PATH_FUNCTIONS}/theme`
export const PATH_FUNCTIONS_LINKS = `${PATH_FUNCTIONS}/links`
export const PATH_FUNCTIONS_QR = `${PATH_FUNCTIONS}/qr`
