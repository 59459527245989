import React, { useState, useEffect, useRef } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import P5Sketch from '../../components/p5-sketch';

const AnimationScreen = React.memo(({ collectibles, person, personPoints, setPersonPoints, tracks, handleTrackChange }) => {
    const [isGreen, setIsGreen] = useState(false);
    const [greenDuration, setGreenDuration] = useState(100000);
    const greenTimeoutRef = useRef(null);

    useEffect(() => {
        // console.log('AnimationScreen person', personPoints);
    }, [personPoints]);

    useEffect(() => {
        if (isGreen) {
            if (greenTimeoutRef.current) {
                clearTimeout(greenTimeoutRef.current);
            }
            greenTimeoutRef.current = setTimeout(() => {
                setIsGreen(false);
            }, greenDuration);
        }
        return () => clearTimeout(greenTimeoutRef.current);
    }, [isGreen, greenDuration]);

    return (
        <TelegramScreen className={`game`} id="gesuredZone">
            <div className='main-container'>
                <div className='sketch'>
                    <P5Sketch personPoints={personPoints} setPersonPoints={setPersonPoints} isGreen={isGreen} setIsGreen={setIsGreen} tracksList={tracks} onTrackChange={handleTrackChange}  />
                </div>
                <MainNavigation currentPage={'main'} />
            </div>
        </TelegramScreen>
    );
});

export default AnimationScreen;