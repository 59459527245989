import React, { useState, useEffect, useRef } from 'react';
import Sketch from 'react-p5';
import "p5/lib/addons/p5.sound";
import TelegramText from '../components/kit/Text/TelegramText';
import PPIcon from "../assets/icons/pp-small.svg";
import TelegramButton from '../components/kit/Button/TelegramButton';
import { ReactComponent as BuyTokenIon } from '../assets/icons/buy-token.svg';
import './P5Game.css';
import { sendPointsToServer } from '../logic/server/api';
import { ReactComponent as PlayIcon } from '../assets/icons/play-filled-alt.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/pause-filled.svg';
import { ReactComponent as SkipBackIcon } from '../assets/icons/skip-back.svg';
import { ReactComponent as SkipForwardIcon } from '../assets/icons/skip-forward.svg';
import { ReactComponent as ShareIcon } from '../assets/icons/share.svg';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, FacebookIcon, TwitterIcon, TelegramIcon } from 'react-share';

const XIcon = ({ size = 32, round = false }) => (
    <div style={{ width: size, height: size, borderRadius: round ? '50%' : '0', background: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        X
    </div>
);

let amplitude;
let audio;
let logo;
let currentTrackIndex = 0;

function P5Game({ personPoints, setPersonPoints, tracksList, onTrackChange }) {
    const [tracks, setTracks] = useState([]);
    const [audioUrl, setAudioUrl] = useState(null);
    const [points, setPoints] = useState(0);
    const [lastChange, setLastChange] = useState(0);
    const [showLastChange, setShowLastChange] = useState(false);
    const [shadowCircleSize, setShadowCircleSize] = useState(150);
    const [lastClickTime, setLastClickTime] = useState(0);
    const [currentInnerCircleSize, setCurrentInnerCircleSize] = useState(160);
    const [shrinkSpeed, setShrinkSpeed] = useState(1.2);
    const [inflateFactor, setInflateFactor] = useState(1.5);
    const [tolerance, setTolerance] = useState(14);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isGreen, setIsGreen] = useState(false);
    const [isRed, setIsRed] = useState(false);
    const [greenDuration, setGreenDuration] = useState(500);
    const [redDuration, setRedDuration] = useState(300);
    const [tokenLogo, setTokenLogo] = useState(null)
    const greenTimeoutRef = useRef(null);
    const redTimeoutRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const p5ref = useRef(null);
    const audioRef = useRef(null);
    const pointsRef = useRef(null);
    const isFirstLoadRef = useRef(null);
    const [showShareTooltip, setShowShareTooltip] = useState(false);

    useEffect(() => {
        if (tracksList && tracksList.length > 0) {
            setTracks(tracksList);
            currentTrackIndex = 0;
            setAudioUrl(tracksList[currentTrackIndex].url);
        }
    }, [tracksList]);

    useEffect(() => {
        if (personPoints !== null) {
            setLoading(false);
        }
    }, [personPoints]);

    useEffect(() => {
        if (isGreen) {
            greenTimeoutRef.current = setTimeout(() => {
                setIsGreen(false);
            }, greenDuration);
        }
        return () => clearTimeout(greenTimeoutRef.current);
    }, [isGreen, greenDuration]);

    useEffect(() => {
        if (isRed) {
            redTimeoutRef.current = setTimeout(() => {
                setIsRed(false);
            }, redDuration);
        }
        return () => clearTimeout(redTimeoutRef.current);
    }, [isRed, redDuration]);

    const setup = (p5, canvasParentRef) => {
        p5ref.current = p5;
        p5.createCanvas(360, 360).parent(canvasParentRef);
        p5.noStroke();
        p5.ellipseMode(p5.CENTER);

        if (!audioUrl) return;

        audioRef.current = p5ref.current.loadSound(audioUrl, () => {
            // Auto-play not working without user interaction
            if (!isFirstLoadRef.current) {
                if (tracks[currentTrackIndex].plays === 0) {
                    setIsPlaying(false);
                    return;
                }
                audioRef.current.play();
                if (audioRef.current.isPlaying()) {
                    setIsPlaying(true);
                }
            }
            isFirstLoadRef.current = false;
        });
        amplitude = new p5.constructor.Amplitude();
        amplitude.setInput(audioRef.current);

        p5.loadImage(tracks[currentTrackIndex].logoImage, (img) => {
            setTokenLogo(img);
        });

        setShadowCircleSize(p5.random(120, 150));
    };

    const draw = (p5) => {
        p5.background(0);
        p5.noFill();
        p5.stroke(255, 255, 255, 40);
        p5.strokeWeight(1);
        p5.ellipse(p5.width / 2, p5.height / 2, shadowCircleSize * 2);

        const level = amplitude && audioRef.current && audioRef.current.isPlaying() ? amplitude.getLevel() : 0;

        const minRadius = 80;
        const maxRadius = 111 * inflateFactor;
        const targetRadius = p5.map(level, 0, 0.25, minRadius, maxRadius);

        if (targetRadius > currentInnerCircleSize) {
            setCurrentInnerCircleSize(targetRadius);
        } else {
            setCurrentInnerCircleSize(Math.max(minRadius, currentInnerCircleSize - shrinkSpeed));
        }

        let fillColor, strokeColor;
        if (isRed) {
            fillColor = createGradient(p5, 'rgba(255, 4, 4, 0.25)', 'rgba(255, 4, 4, 0)');
            strokeColor = p5.color(255, 4, 4);
        } else if (isGreen) {
            fillColor = createGradient(p5, 'rgba(4, 255, 4, 0.25)', 'rgba(4, 255, 4, 0)');
            strokeColor = p5.color(4, 255, 4);
        } else if (Math.abs(currentInnerCircleSize - shadowCircleSize) < tolerance) {
            fillColor = createGradient(p5, 'rgba(4, 255, 4, 0.25)', 'rgba(4, 255, 4, 0)');
            strokeColor = p5.color(4, 255, 4);
            if (!isGreen) {
                setIsGreen(true);
            }
        } else {
            fillColor = createGradient(p5, 'rgba(169, 58, 255, 0.25)', 'rgba(169, 58, 255, 0)');
            strokeColor = p5.color(169, 58, 255);
        }

        if (tokenLogo) {
            const naturalWidth = tokenLogo.width;
            const naturalHeight = tokenLogo.height;
            const maxIconSize = currentInnerCircleSize * 2;
            const minIconSize = currentInnerCircleSize;
            const scale = Math.min(maxIconSize / naturalWidth, maxIconSize / naturalHeight, 1);
            const iconWidth = Math.max(naturalWidth * scale, minIconSize);
            const iconHeight = Math.max(naturalHeight * scale, minIconSize);
    
            const amplitudeScale = 1;
            const pulsatingIconWidth = iconWidth * amplitudeScale;
            const pulsatingIconHeight = iconHeight * amplitudeScale;
    
            p5.image(tokenLogo, p5.width / 2 - pulsatingIconWidth / 2, p5.height / 2 - pulsatingIconHeight / 2, pulsatingIconWidth, pulsatingIconHeight);
        }

        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.fill(255);

        p5.drawingContext.fillStyle = fillColor;
        p5.stroke(strokeColor);
        p5.strokeWeight(2);
        p5.ellipse(p5.width / 2, p5.height / 2, currentInnerCircleSize * 2);
    };

    const createGradient = (p5, color1, color2) => {
        const gradient = p5.drawingContext.createLinearGradient(0, 0, 0, p5.height);
        gradient.addColorStop(1, color1);
        gradient.addColorStop(0, color2);
        return gradient;
    };

    const mousePressed = (p5) => {
        const currentTime = p5.millis();
        const mouseX = p5.mouseX;
        const mouseY = p5.mouseY;
        const centerX = p5.width / 2;
        const centerY = p5.height / 2;
        const distance = p5.dist(mouseX, mouseY, centerX, centerY);

        if (distance > currentInnerCircleSize || !audioRef.current || !audioRef.current.isPlaying() || currentTime - lastClickTime < 200) {
            return;
        }

        setLastClickTime(currentTime);

        if (isGreen) {
            setPoints(points + tracks[currentTrackIndex].reward);
            setLastChange(tracks[currentTrackIndex].reward);
            setShowLastChange(true);
        } else {
            setPoints(Math.max(points - tracks[currentTrackIndex].reward, 0));
            setLastChange(-tracks[currentTrackIndex].reward);
            setShowLastChange(true);
            setIsRed(true);
            setIsGreen(false);
        }

        setTimeout(() => {
            setShowLastChange(false);
        }, 400);
    };

    const togglePlayPause = () => {
        if (tracks[currentTrackIndex].plays === 0 || !audioRef || !audioRef.current) {
            return;
        }
        if (audioRef.current.isPlaying()) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.play();
            setIsPlaying(true);
        }
    };
    
    const nextTrack = async () => {
        if (audioRef.current && audioRef.current.isPlaying()) {
            audioRef.current.stop();
        }

        if (points > 0) {
            await sendPointsToServer(points);
            setPersonPoints(prevPoints => prevPoints + points);
            setPoints(0);
            tracks[currentTrackIndex].plays -= 1;
        }

        currentTrackIndex = (currentTrackIndex + 1) % tracks.length;
        setAudioUrl(tracks[currentTrackIndex].url);

        if (!p5ref) {
            return;
        }
        audioRef.current = p5ref.current.loadSound(tracks[currentTrackIndex].url, () => {
            if (tracks[currentTrackIndex].plays === 0) {
                setIsPlaying(false);
                return;
            }
            audioRef.current.play();
            if (audioRef.current.isPlaying()) {
                setIsPlaying(true);
            }
        });
        amplitude = new p5ref.current.constructor.Amplitude();
        amplitude.setInput(audioRef.current);

        p5ref.current.loadImage(tracks[currentTrackIndex].logoImage, (img) => {
            setTokenLogo(img);
        });
        
        onTrackChange(tracks[currentTrackIndex].title);
        setShowShareTooltip(false);
    };

    const previousTrack = async () => {
        if (audioRef.current && audioRef.current.isPlaying()) {
            audioRef.current.stop();
        }

        if (points > 0) {
            await sendPointsToServer(points);
            setPersonPoints(prevPoints => prevPoints + points);
            setPoints(0);
            tracks[currentTrackIndex].plays -= 1;
        }

        currentTrackIndex = (currentTrackIndex - 1 + tracks.length) % tracks.length;
        setAudioUrl(tracks[currentTrackIndex].url);

        if (!p5ref) {
            return;
        }
        audioRef.current = p5ref.current.loadSound(tracks[currentTrackIndex].url, () => {
            if (tracks[currentTrackIndex].plays === 0) {
                setIsPlaying(false);
                return;
            }
            audioRef.current.play();
            if (audioRef.current.isPlaying()) {
                setIsPlaying(true);
            }
        });
        amplitude = new p5ref.current.constructor.Amplitude();
        amplitude.setInput(audioRef.current);

        p5ref.current.loadImage(tracks[currentTrackIndex].logoImage, (img) => {
            setTokenLogo(img);
        });
        
        onTrackChange(tracks[currentTrackIndex].title);
        setShowShareTooltip(false);
    };

    useEffect(() => {
        if (audioRef.current && !audioRef.current.isPlaying() && p5ref) {
            console.log('Playing next track', currentTrackIndex, tracks.length);


            // currentTrackIndex = currentTrackIndex + 1;
            // setAudioUrl(tracks[currentTrackIndex % tracks.length]);
            // audio.play();
            // setIsPlaying(true);
        }
    }, [audioUrl]);

    useEffect(() => {
        pointsRef.current = points;
    }, [points]);

    useEffect(() => {
        return () => {
            if (audioRef.current && !audioRef.current.paused) {
                audioRef.current.stop();
            }
            if (pointsRef.current > 0) {
                sendPointsToServer(pointsRef.current);
                setPersonPoints(prevPoints => prevPoints + pointsRef.current);
                setPoints(0);
                tracks[currentTrackIndex].plays -= 1;
            }
        };
    }, []);

    const shareTrack = () => {
        const trackTitle = tracks[currentTrackIndex]?.title;
        const url = `${window.location.origin}/${encodeURIComponent(trackTitle)}`;
        navigator.clipboard.writeText(url).then(() => {
            alert('Link copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    return (
        <div className="game-wrapper">
            <Sketch setup={setup} draw={draw} mousePressed={mousePressed} />
            {tracks.length > 0 && (
                <>
                    <TelegramText style={{ textAlign: 'center' }} className={'telegramMainTitle'}>
                        {tracks[currentTrackIndex].title}
                    </TelegramText>
                    <TelegramText style={{ textAlign: 'center' }} className={'telegramSubtitle'}>
                        {`${tracks[currentTrackIndex].reward} PP per click, ${tracks[currentTrackIndex].plays || 0} play${tracks[currentTrackIndex].plays === 1 ? '' : 's'} left`}
                    </TelegramText>
                </>
            )}
            <TelegramText style={{ textAlign: 'center' }} className={'telegramPrimaryText'}>
                <span style={{ display: 'inline-block', position: 'relative' }}>
                    <img src={PPIcon} alt={'Powder'} />
                    {loading ? (
                        <div className="spinner-small"></div>
                    ) : (
                        `${personPoints + points} PP`
                    )}
                    {showLastChange && (
                        <span className={`lastChange ${lastChange > 0 ? 'positive' : 'negative'}`}>
                            {lastChange > 0 ? ` +${lastChange}` : ` ${lastChange}`}
                        </span>
                    )}
                </span>
            </TelegramText>
    
            <div className="share-holder">
                <div className='buttons-holder'>
                    <button
                        className={`outlined ${isGreen ? 'green' : ''} ${isRed ? 'red' : ''}`}
                        onClick={togglePlayPause}
                        disabled={tracks.length === 0 || tracks[currentTrackIndex].plays === 0}
                    >
                        {isPlaying ? <PauseIcon /> : <PlayIcon />}
                    </button>
                    {/* <TelegramButton style={{ flexGrow: '1' }}><BuyTokenIon /> Buy RAPB Token</TelegramButton> */}
                    <button
                        className={`outlined ${isGreen ? 'green' : ''} ${isRed ? 'red' : ''}`}
                        onClick={previousTrack}
                    >
                        <SkipBackIcon />
                    </button>
                    <button
                        className={`outlined ${isGreen ? 'green' : ''} ${isRed ? 'red' : ''}`}
                        onClick={nextTrack}
                    >
                        <SkipForwardIcon />
                    </button>
                    <button
                        className={`outlined`}
                        onClick={() => setShowShareTooltip(!showShareTooltip)}
                    >
                        <ShareIcon />
                    </button>
                </div>
                {showShareTooltip && (
                    <div className="share-tooltip">
                        <FacebookShareButton url={`${window.location.origin}/${encodeURIComponent(tracks[currentTrackIndex]?.title).toLowerCase()}.html`} quote={`Check this out! ${tracks[currentTrackIndex]?.title}`}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={`${window.location.origin}/${encodeURIComponent(tracks[currentTrackIndex]?.title).toLowerCase()}.html`} title={`Check this out! ${tracks[currentTrackIndex]?.title}`}>
                            <XIcon size={32} round />
                        </TwitterShareButton>
                        <TelegramShareButton url={`${window.location.origin}/${encodeURIComponent(tracks[currentTrackIndex]?.title).toLowerCase()}.html`} title={`Check this out! ${tracks[currentTrackIndex]?.title}`}>
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </div>
                )}
            </div>
        </div>
    );
}

export default P5Game;