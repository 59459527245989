export const API_URL = 'https://dev.em.techfunder.de';
export const BRN_URL = 'https://api.brn.ai';
// export const BOT_ID = 'KY24L16X12'; //realty
export const BOT_ID = '7RNOV9GFRH';
export const MERCHANT_ID = 'KC7TL70LD6';
export const TASK_ID = '674869237beb8d5aec1d2383';

export const SlEEP_TIME = 0.3 * 60 * 1000;
export const ACTIVE_TIME = 0.6 * 60 * 1000;


export const APP_VERSION = '1.0.1';
