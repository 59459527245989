import React, { useEffect, useState } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTelegram } from "../../hooks/useTelegram";

import BlueIcon from "../../assets/solar_circle-bottom-up-bold-duotone.svg";
import CancelledIcon from "../../assets/solar_chat-round-money-bold-duotone.svg";
import CloseIcon from "../../assets/close-circle-white.svg";
import { equipItem, getCollectibles, getEquipment, sellPaddle } from "../../logic/server/api";
import TelegramButton from '../../components/kit/Button/TelegramButton';
import TelegramText from '../../components/kit/Text/TelegramText';
import WelcomeImage from '../../assets/welcome-image3.png';
import PPIcon from "../../assets/icons/pp-big.svg";
import UserIcon from "../../assets/icons/user.png";
import { ReactComponent as NoImage } from "../../assets/icons/no-image.svg";
import { ReactComponent as CopyIcon } from '../../assets/icons/icon-copy.svg';
import { ReactComponent as ClaimIcon } from '../../assets/icons/claim.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark-filled.svg';

interface Collectible {
    name: string;
    image: string,
    asset: string,
    type: string,
    slot: string,
    rarity: string,
    item_id: string,
    collection_id: string,
    quantity: number
    price: number
}

interface CollectiblesResponse {
    items: Collectible[];
    status: boolean;
}

const CollectiblesPage = React.memo(() => {
    const [collectibles, setCollectibles] = useState<Collectible[]>([]);
    const [equipment, setEquipment] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedPaddle, setSelectedPaddle] = useState<Collectible | null>(null);
    const [quantity, setQuantity] = useState<string>('1');
    const [isOpen, setIsOpen] = useState(false);
    const [maxLimit, setMaxLimit] = useState(0);
    const [initialHeight, setInitialHeight] = useState(window.innerHeight);
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const code = localStorage.getItem('code') ? `?start=${localStorage.getItem('code')}` : '';
    const copyLink = `https://t.me/brnzbot` + code + `&text=Join BRNZ Game!`;

    useEffect(() => {
        setInitialHeight(window.innerHeight);
        getCollectiblesData();
        getEquip();
    }, []);

    // Detect viewport changes (keyboard open/close)
    useEffect(() => {
        const handleResize = () => {
            if (window.innerHeight < initialHeight - 100) {
                setKeyboardOpen(true);
            } else {
                setKeyboardOpen(false);
            }
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [initialHeight]);

    async function getEquip() {
        const resp: any = await getEquipment();
        if (resp?.status && resp?.equipment?.paddle?.item_id) {
            setEquipment(resp.equipment.paddle.item_id);
        }
        setLoading(false);

    }
    async function getCollectiblesData() {
        const resp: any = await getCollectibles();
        if (resp?.status) {
            setCollectibles(resp.items);
        }
    }

    async function onEquip(id: string) {
        const resp: any = await equipItem(id);
        if (resp?.status) {
            getEquip();
        }
    }

    async function onCopyLink() {
        await navigator.clipboard.writeText(copyLink);
    }

    function onSelectPaddle(item: Collectible) {
        if (item.quantity && (!equipment || equipment && (equipment !== item?.item_id))) {
            setLoading(true);
            onEquip(item.item_id);
        }
    }

    const handleChange = (evt: any) => {
        const newValue = !isNaN(evt?.target?.value) ? evt?.target?.value : 0;

        if (selectedPaddle && (newValue === '' || Number(newValue) >= 0 && Number(newValue) <= (maxLimit))) {
            setQuantity(newValue);
        }
        if (selectedPaddle && Number(newValue) >= 0 && Number(newValue) > (maxLimit)) {
            toast.error(`You don't have enough paddles to sell`);

        }
    };

    const onOpenModal = (item: Collectible) => {
        if (item.quantity >= 1) {
            const limit = equipment === item.item_id ? item.quantity - 1 : item.quantity;
            setMaxLimit(limit);
            setSelectedPaddle(item);
            setTimeout(() => { setIsOpen(true) }, 100);
        }
    }

    const handleIncrement = () => {
        if (selectedPaddle && quantity && (Number(quantity) + 1 <= (maxLimit))) {
            setQuantity((prevValue) => prevValue === '' ? '1' : String(Number(prevValue) + 1));
        }
    };

    // Handle Decrement
    const handleDecrement = () => {
        if ((quantity !== '') && Number(quantity) - 1 > 0) {
            setQuantity((prevValue) => String(Number(prevValue) - 1));
        }
    };

    const handleBlur = () => {
        if (quantity === '') {
            setQuantity('1'); // Reset to 0 or some default if the value is empty
        }
    };

    const onCloseModal = () => {
        setQuantity('1');
        setIsOpen(false);
        setMaxLimit(0);
        setTimeout(() => setSelectedPaddle(null), 500);
    }


    async function sellRequest() {
        if (selectedPaddle && quantity && (Number(quantity) <= (maxLimit))) {
            const resp: any = await sellPaddle(selectedPaddle.item_id, Number(quantity));
            if (resp?.status) {
                setLoading(true);
                getCollectiblesData();
                toast.success(`You sold ${quantity} ${selectedPaddle.name} successfully!`);

                setTimeout(() => {
                    setQuantity('1');
                    setMaxLimit(0);
                    setIsOpen(false);
                    setSelectedPaddle(null);
                    setLoading(false);
                }, 300);
            }
        } if (selectedPaddle && quantity && (Number(quantity) > (maxLimit))) {
            // notification
            toast.error(`You don't have enough paddles to sell`);
        }
    }
    const onSellPaddle = () => {
        sellRequest();

    }

    return (
        <TelegramScreen className={``} id='gesuredZone'>
            {loading ? (<div className="loader-container">
                <div className="spinner"></div>
            </div>) : null}

            <div className='main-container'>
                {/* <div className='welcome-holder  '>
                    <div className="columns-holder">
                        <div className="column">
                            <div className="welcome">
                                <TelegramText className={'telegramMainTitle'}><img src={PPIcon} alt={'Powder'} /> 6274 PP</TelegramText>
                                <TelegramText className={'white-opacity'}>Earned from referrals.You receive<br />15% of all tokens, that collects your <br />7 referrals</TelegramText>
                                <TelegramButton className={'block'} onClick={onCopyLink}><CopyIcon /> Copy invitation link</TelegramButton>
                                <div className="decor">
                                    <img src={WelcomeImage} alt={'Welcome'} />
                                </div>
                            </div>
                        </div>
                        <div className="column"> */}
                            <div className='cards-container cards-container-dim'>
                                <div className={'cards-container--top'}>
                                    <div className={'card__tasks--title'}>
                                        <p>{collectibles.length} items in inventory</p>
                                    </div>
                                    <div className={'cards__container cards__container--collectibles'}>
                                        {/* <div className={'container-text-for-collectibles'}>
                                            <p className={'blue-roboto-14'}>Paddles</p>
                                            <p className={'gray-roboto-14'}>4/{collectibles?.length} owned</p>
                                        </div> */}




                                        {collectibles.length > 0 ? (
                                            collectibles.map((item: any, index: number) => (
                                                <div 
                                                    onClick={() => onSelectPaddle(item)}
                                                    className={`card-inventory
                                                        ${equipment && (equipment === item?.item_id) && (item?.quantity > 0) ? 'card--selected'
                                                            : ''} ${item?.quantity === 0 ? 'card--disabled' : 'card--active'}`} 
                                                    key={item.id}>
                                                        <div className="card-inventory--img">
                                                            <NoImage />
                                                        </div>
                                                        <div className="card-inventory--content">
                                                            <div className="card-inventory--title">#{index}</div>
                                                            <div className="card-inventory--text"></div>
                                                        </div>
                                                        <img 
                                                            width={24} 
                                                            height={24} 
                                                            src={UserIcon} 
                                                            alt="description"
                                                            className={`hidden card--img ${item.quantity === 0 ? 'card--img--grayscale' : ''}`} 
                                                        />
                                                        <div className="hidden">
                                                            <p className={item.rarity === 'common' ? 'rarity-label rarity-common' : 'rarity-label rarity-rare'}>
                                                                {item.rarity === 'common' ? 'Common' : 'Super Rare'}
                                                            </p>
                                                        </div>

                                                    <div className={"wrapper-for-card "}>
                                                        <div className={'id-of-selector hidden'}>
                                                            {item.quantity}
                                                        </div>
                                                        <p className={'card__task--text hidden'}>#{index}</p>

                                                        <div className={"row-text-for-collectibles"}>
                                                            <CheckmarkIcon />
                                                            {equipment && (equipment !== item.item_id) && (<p className={'blue-roboto-12 gap-for-icons'}>
                                                                <img src={BlueIcon} alt="Equip Icon" />
                                                                {'Equip'}</p>)}

                                                            {equipment && (equipment === item.item_id) && (
                                                                <p className={'gray-roboto-12 gap-for-icons'}>
                                                                    {'+Equipped'}</p>)}

                                                                <p className={'blue-roboto-12 gap-for-icons'}>{item.price} PP</p>
                                                                
                                                            <p className={`blue-roboto-12 gap-for-icons 
                                                                ${item.quantity < 1 || (item.item_id === equipment && item.quantity <= 1) ? 'disabled' : ''}`}
                                                                onClick={(evt) => { onOpenModal(item); evt.stopPropagation(); }}>
                                                                <img 
                                                                    src={CancelledIcon} 
                                                                    alt="Cancelled Icon"
                                                                    className={`${item.quantity < 1 || (item.item_id === equipment && item.quantity <= 1) ? 'hidden modal-sell-img-dim' : ''}`} 
                                                                />
                                                                <ClaimIcon />
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p>No collectibles available</p>
                                        )}


                                    </div>
                                </div>
                                <div className='forms__bottom'>
                                    <MainNavigation currentPage={'collectibles'} />
                                </div>
                            </div>
                        {/* </div>
                    </div>
                </div> */}
            </div>



            {selectedPaddle && (
                <div className={`sell-modal ${isOpen ? 'open' : ''}`}>
                    <div className={'sell-modal--header'}>
                        <p className={'sell-modal--header--text'}>Sell</p>
                        <div onClick={() => onCloseModal()}>
                            <img alt='close-icon' src={CloseIcon} className={'modal-close-icon'} /></div>
                    </div>
                    <div className={`modal-main-container`}>
                        <div className={'card--img-container'}>
                            <img alt={'paddle'} src={selectedPaddle.image} className={`modal-main-container--img  ${keyboardOpen ? 'modal-main-container--img-min' : ''}`} />
                            <p className={selectedPaddle.rarity === 'common' ? 'rarity-common rarity-common--in-modal'
                                : 'rarity-rare rarity-rare--in-modal'}>
                                {selectedPaddle.rarity === 'common' ? 'Common' : 'Super Rare'}
                            </p>
                        </div>
                        <p className={'modal-main-container--text'}>{selectedPaddle.name}</p>
                    </div>
                    <div className={'sell-modal--info'}>
                        <div className={'sell-modal--row'}>
                            <div className={'sell-modal--row-text'}><p>Price</p></div>
                            <div className={'sell-modal--row-price'}>{selectedPaddle.price} PP</div>
                        </div>
                        <div className={'sell-modal--row'}>
                            <div className={'sell-modal--row-text'}><p>Quantity</p>
                                <p className={'sell-modal--row-text-mini'}>Max: {maxLimit}</p>
                            </div>
                            <div className="number-input-wrapper">
                                <button className="number-input--btn minus" onClick={handleDecrement}>−</button>
                                <input
                                    type="number"
                                    value={quantity}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    max={maxLimit}
                                    className="modal-number-input"
                                />
                                <button className="number-input--btn plus" onClick={handleIncrement}>+</button>
                                {Number(quantity) === (maxLimit) && (
                                    <div className={'sell-modal--row-text-mini'} style={{ marginLeft: '5px' }}>MAX</div>)}

                            </div>
                        </div>
                        <div className={'sell-modal--row'}>
                            <div className={'sell-modal--row-text'}><p>Total</p></div>
                            <div className={'sell-modal--row-price'}>{selectedPaddle.price * Number(quantity)} PP</div>
                        </div>
                    </div>
                    <div className={'sell-modal--button'}>
                        <button className={'btn-for-copy-links'}
                            onClick={() => onSellPaddle()}>Confirm</button>
                    </div>
                </div>)}


            <ToastContainer
                position="bottom-center"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>
    );
});

export default CollectiblesPage;
