import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import { ReactComponent as LogoGame } from '../../assets/logo.svg';
import LogoBRNZ from '../../assets/logo-brnz.png';
import { ReactComponent as RefreshIcon } from '../../assets/icon-refresh.svg';
import TelegramText from '../../components/kit/Text/TelegramText';

const SplashScreen = ({ style }) => {
  return (
    <TelegramScreen className='splash-wrapper'>
      <div className='splash-wrapper__holder' style={{ 'justifyContent': 'end', ...style }}>
        <div className='splash-wrapper__frame'>
          {/* <LogoGame></LogoGame> */}
          <img width={46} height={46} src={LogoBRNZ} alt="Brainz Logo" />
          <TelegramText className={'splash-text'}>Send Yo’ Stake, <br />Hear the Break</TelegramText>
          <TelegramText className="loading"><RefreshIcon></RefreshIcon> LOADING...</TelegramText>
        </div>
      </div>
    </TelegramScreen>)
}

export default SplashScreen
