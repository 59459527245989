import React, { useState } from 'react';
import { ReactComponent as AboutIcon } from '../../../assets/icons/about.svg';
import { ReactComponent as PlayIcon } from '../../../assets/game-page.svg';
import { ReactComponent as TasksIcon } from '../../../assets/tasks-page.svg';
import { ReactComponent as FriendsIcon } from '../../../assets/friends-page.svg';
import { ReactComponent as RedeemIcon } from '../../../assets/redeem-page.svg';
import { ReactComponent as BagIcon } from '../../../assets/bag-icon.svg';
import { ReactComponent as Logo } from '../../../assets/logo.svg';
import LogoBRNZ from '../../../assets/logo-brnz.png';
import { PATH_CLAIM, PATH_COLLECTIBLES, PATH_EARNED, PATH_MAIN, PATH_ABOUT, PATH_TASKS, PATH_CHARTS } from "../../../constants/Paths";
import { useNavigate } from "react-router-dom";
import MainNavigationDropdown from './MainNavigationDropdown';

const MainNavigation = ({ currentPage }) => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleMoreClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleNavigate = (path) => {
        navigate(path);
        setIsDropdownOpen(false);
    };

    return (
        <div className='main-navigation'>
            <div className="main-navigation__holder">
                <div className="logo">
                    {/* <Logo></Logo> */}
                    <img width={46} height={46} src={LogoBRNZ} alt="Brainz Logo" />
                </div>
                <div className='main-navigation-container'>
                    <div className={`main-navigation__cards ${currentPage === 'main' ? 'main-navigation__cards--active' : ''}`}
                        onClick={() => handleNavigate(PATH_MAIN)}>
                        <PlayIcon className={'main-navigation__icon'} />
                        <div className={`forms__bottom-results-container forms__bottom-icon--day-mode`}>
                            <div className='main-navigation__text'>Game</div>
                        </div>
                    </div>
                    <div className={`main-navigation__cards ${currentPage === 'tasks' ? 'main-navigation__cards--active' : ''} `}
                        onClick={() => handleNavigate(PATH_TASKS)}>
                        <TasksIcon className={'main-navigation__icon'} />
                        <div className='forms__bottom-results-container'>
                            <div className='main-navigation__text'>Tasks</div>
                        </div>
                    </div>
                    <div className={`main-navigation__cards ${currentPage === 'leaderboard' || currentPage === 'create' ? 'main-navigation__cards--active' : ''}  `}
                        onClick={() => handleNavigate(PATH_CHARTS)}>
                        <BagIcon className={'main-navigation__icon'} />
                        <div className='forms__bottom-results-container'>
                            <div className='main-navigation__text'>Top Chart</div>
                        </div>
                    </div>
                    <div className={`main-navigation__cards ${currentPage === 'earnings' ? 'main-navigation__cards--active' : ''} `}
                        onClick={() => handleNavigate(PATH_EARNED)}>
                        <FriendsIcon className={'main-navigation__icon'} />
                        <div className='forms__bottom-results-container'>
                            <div className='main-navigation__text'>Invites</div>
                        </div>
                    </div>
                    <div className={`main-navigation__cards ${currentPage === 'collectibles' || currentPage === 'redeem' || currentPage === 'about' ? 'main-navigation__cards--active' : ''} `}
                        onClick={handleMoreClick}>
                        <RedeemIcon className={'main-navigation__icon'} />
                        <div className='forms__bottom-results-container'>
                            <div className='main-navigation__text'>More</div>
                        </div>
                    </div>
                </div>
            </div>
            {isDropdownOpen && <MainNavigationDropdown currentPage={currentPage} onNavigate={handleNavigate} />}
        </div>
    );
};

export default MainNavigation;