import React, { useState, useRef, useEffect } from 'react';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { useNavigate } from "react-router-dom";

import TelegramText from "../../components/kit/Text/TelegramText";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import WelcomeImage from '../../assets/backgrounds/leaderboard-bg.jpeg';
import LeaderBoardRow from './LeaderBoardRow';
import LeaderBoardGrid from './LeaderBoardGrid';

import song1 from '../../assets/mp3/song1.mp3';
import song2 from '../../assets/mp3/song2.mp3';
import song3 from '../../assets/mp3/song3.mp3';
import song4 from '../../assets/mp3/song4.mp3';

import iconBase from '../../assets/icons/icon-base.svg';
import iconBitcoin from '../../assets/icons/icon-bitcoin.svg';
import iconSolana from '../../assets/icons/icon-solana.svg';
import iconEthereum from '../../assets/icons/icon-ethereum.svg';
import iconPolygon from '../../assets/icons/icon-polygon.svg';

import { ReactComponent as ChevronSort } from '../../assets/icons/chevron-sort.svg';
import { PATH_CREATE } from '../../constants/Paths';

let tracks = [
    { url: "https://cdn1.suno.ai/019785e8-1663-4a8b-845c-67029477f81c.mp3", title: "Crypto Vault", reward: "20 PP per click, 2 plays left", votes: 3700, logoImage: iconBase, logoText: "CVC" },
    { url: song1, title: "Crypto Matrix", reward: "19 PP per click, 2 plays left", votes: 2500, logoImage: iconBitcoin, logoText: "CRM" },
    { url: song2, title: "Crypto Pal", reward: "18 PP per click, 2 plays left", votes: 1800, logoImage: iconSolana, logoText: "CPL" },
    { url: song3, title: "RapBitcoin", reward: "17 PP per click, 1 play left", votes: 1200, logoImage: iconEthereum, logoText: "RAPB" },
    { url: song4, title: "Chain of Things", reward: "16 PP per click, 2 plays left", votes: 900, logoImage: iconPolygon, logoText: "COT" },
];

const LeaderBoard = () => {
    const navigate = useNavigate();
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [view, setView] = useState('list');
    const audioRef = useRef(null);
    
    const handleNavigate = (path) => {
        navigate(path);
    };

    const handleVote = () => {
        console.log('Vote clicked');
    };

    const handlePlayPause = (track) => {
        if (currentTrack && currentTrack.url === track.url) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        } else {
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
            setCurrentTrack(track);
            setIsPlaying(true);
            setProgress(0);
            audioRef.current.src = track.url;
            audioRef.current.play();
        }
    };

    const handleProgress = (progress) => {
        setProgress(progress);
    };

    const toggleView = () => {
        setView(view === 'list' ? 'grid' : 'list');
    };

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (audioRef.current) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                setProgress(progress);
            }
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, []);

    return (
        <TelegramScreen className={``} id='gesuredZone'>
            <div className='main-container'>
                <div className='welcome-holder'>
                    <div className="columns-holder">
                        <div className="column">
                            <div className="welcome">
                                <TelegramText className={'telegramMainTitle'}><p className={'text-bold-roboto'}>Top Chart</p></TelegramText>
                                <TelegramText className={'white-opacity'}>All generated music shows up here. More votes it receive – higher place it takes. You can vote for one track as many times, as you want</TelegramText>
                                <TelegramButton className={'block'} onClick={() => handleNavigate(PATH_CREATE)}>Create a new track</TelegramButton>
                                <div className="decor">
                                    <img src={WelcomeImage} alt={'Welcome'} />
                                </div>
                                {currentTrack && (
                                    <div className="current-track-player">
                                        <LeaderBoardRow
                                            trackUrl={currentTrack.url}
                                            title={currentTrack.title}
                                            reward={currentTrack.reward}
                                            votes={currentTrack.votes}
                                            onVote={handleVote}
                                            onPlayPause={() => handlePlayPause(currentTrack)}
                                            isPlaying={isPlaying}
                                            onProgress={handleProgress}
                                            volume={0}
                                            isPlayer={true}
                                            index={tracks.findIndex(track => track.url === currentTrack.url)}
                                            logoImage={currentTrack.logoImage}
                                            logoText={currentTrack.logoText}
                                        />
                                        <div className="progress-bar">
                                            <div className="progress" style={{ width: `${progress}%` }}></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="column">
                            <div className="leaderboard">
                                <button onClick={toggleView} className="toggle-view-button">
                                    <ChevronSort />
                                    {view === 'list' ? 'Switch to Grid view' : 'Switch to List view'}
                                </button>
                                {view === 'list' ? (
                                    <div className="leaderboard-list">
                                        {tracks.map((track, index) => (
                                            <LeaderBoardRow
                                                key={index}
                                                trackUrl={track.url}
                                                title={track.title}
                                                reward={track.reward}
                                                votes={track.votes}
                                                onVote={handleVote}
                                                onPlayPause={() => handlePlayPause(track)}
                                                isPlaying={currentTrack && currentTrack.url === track.url && isPlaying}
                                                onProgress={currentTrack && currentTrack.url === track.url ? handleProgress : null}
                                                volume={currentTrack && currentTrack.url === track.url ? 1 : 0}
                                                index={index}
                                                logoImage={track.logoImage}
                                                logoText={track.logoText}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <LeaderBoardGrid tracks={tracks} handlePlayPause={handlePlayPause} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <MainNavigation currentPage={'leaderboard'} />
            </div>
            <audio ref={audioRef} />
        </TelegramScreen>
    );
};

export default LeaderBoard;