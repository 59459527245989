import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

import { createTrack } from '../../logic/server/api';

import TelegramText from "../../components/kit/Text/TelegramText";
import TelegramButton from "../../components/kit/Button/TelegramButton";
import WelcomeImage from '../../assets/backgrounds/leaderboard-bg.jpeg';

import song1 from '../../assets/mp3/song1.mp3';
import song2 from '../../assets/mp3/song2.mp3';
import song3 from '../../assets/mp3/song3.mp3';
import song4 from '../../assets/mp3/song4.mp3';

import logoRapB from '../../assets/logo.svg';

import { ReactComponent as AiIcon } from '../../assets/icons/ai-icon.svg';
import { ReactComponent as CurrencyDollar } from '../../assets/icons/currency-dollar.svg';
import { ReactComponent as ImageIcon } from '../../assets/icons/image-icon.svg';
import { ReactComponent as ShortParagraph } from '../../assets/icons/short-paragraph.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/cloud-upload.svg';
import { ReactComponent as PlayFilled } from '../../assets/icons/play-filled.svg';
import { ReactComponent as StopFilled } from '../../assets/icons/stop-filled.svg';
import { PATH_CHARTS, PATH_TASKS } from '../../constants/Paths';


let tracks = [
    { url: "https://cdn1.suno.ai/019785e8-1663-4a8b-845c-67029477f81c.mp3", title: "Diary of a Fallen Star", reward: "20 PP per click, 2 plays left", votes: 3700, logoImage: logoRapB, logoText: "CVCRapBitcoin (RAPB)" },
];

const CreatePage = () => {
    const navigate = useNavigate();
    const [currentTrack, setCurrentTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [step, setStep] = useState(1);
    const [updateCounter, setUpdateCounter] = useState(18);
    const audioRef = useRef(null);

    const { register, handleSubmit, watch, formState: { errors }, setError } = useForm({
        defaultValues: {
            aiRank: 'gold'
        }
    });

    const handlePlayPause = (track) => {
        if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            setCurrentTrack(track);
            setIsPlaying(true);
            audioRef.current.src = track.url;
            audioRef.current.play().catch(error => {
                console.error('Error playing audio:', error);
            });
        }
    };

    const handleProgress = (progress) => {
        setProgress(progress);
    };

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('aiRank', data.aiRank);
        formData.append('investment', data.investment);
        formData.append('lyrics', data.lyrics);
        formData.append('file', data.file[0]);

        try {
            const response = await createTrack(formData);
            // console.log('Response:', response);
            setStep(2);
            // set currentTrack with mock data
            setCurrentTrack(tracks[0]);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handlePublish = async () => {
    };

    const handleUpdate = async () => {
        if (updateCounter > 0) {
            setUpdateCounter(updateCounter - 1);
        }
    };

    const handleNavigate = (path) => {
        navigate(path);
    };

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (audioRef.current) {
                const progress = (audioRef.current.currentTime / audioRef.current.duration) * 100;
                setProgress(progress);
            }
        };

        if (audioRef.current) {
            audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
        }

        return () => {
            if (audioRef.current) {
                audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
            }
        };
    }, []);

    return (
        <TelegramScreen className={``} id='gesuredZone'>
            <div className='main-container'>
                <div className='welcome-holder'>
                    <div className="columns-holder">
                        <div className="column">
                                {step === 1 ? (
                                    <div className="welcome style01">
                                        <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>New track</span></TelegramText>
                                        <TelegramText className={'white-opacity'}>Fill the form to manually create a new track. More investment and higher rank will result in a better song quality. Do not forget about detailed prompts for the lyrics.</TelegramText>
                                        <TelegramButton className={'block hidden-mobile'} onClick={handleSubmit(onSubmit)}>Generate</TelegramButton>
                                        <div className="decor">
                                            <img src={WelcomeImage} alt={'Welcome'} />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="preview-holder">
                                        <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>Review results</span></TelegramText>
                                        <TelegramText className={'mobile-small white-opacity'}>Congratulations! Your new track successfully generated. Now, you can listen and publish it, or re-generate, if current result is not good enough.</TelegramText>
                                        <div className="preview-block">
                                            <div className="welcome style01">
                                                {currentTrack && (
                                                    <>
                                                        <img width={48} height={48} src={currentTrack.logoImage} alt={currentTrack.logoText} />
                                                        <TelegramText className={'telegramSubTitle'}><span className={'text-bold-roboto'}>{currentTrack.logoText}</span></TelegramText>
                                                        <TelegramText className={'white-opacity'}>{currentTrack.title}</TelegramText>
                                                    </>
                                                )}
                                                {currentTrack && (
                                                    <div className="preview" onClick={() => handlePlayPause(currentTrack)}>
                                                        {isPlaying ? <StopFilled /> : <PlayFilled />}
                                                        Preview this track
                                                    </div>
                                                )}
                                                <div className="decor">
                                                    <img src={WelcomeImage} alt={'Welcome'} />
                                                </div>
                                            </div>
                                            <div className="hidden-mobile">
                                                <TelegramText className={'telegramMainTitle'}><span className={'text-bold-roboto'}>Review results</span></TelegramText>
                                                <TelegramText className={'mobile-small white-opacity'}>Congratulations! Your new track successfully generated. Now, you can listen and publish it, or re-generate, if current result is not good enough.</TelegramText>
                                                <div className="cta-buttons">
                                                    <TelegramButton className={'block hidden-mobile'} onClick={handlePublish}>Publish</TelegramButton>
                                                    <TelegramButton className={'block outlined hidden-mobile'} onClick={handleUpdate} disabled={updateCounter === 0}>Update ({updateCounter})</TelegramButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className="column">
                            <form className="form-holder" onSubmit={handleSubmit(onSubmit)}>
                                {step === 1 && <div className="form-item">
                                    <TelegramText className={'regularText'}><b><AiIcon /> Select AI rank</b></TelegramText>
                                    <div className="radio-group">
                                        <label className={`radio-button bronze ${watch('aiRank') === 'bronze' ? 'checked' : ''}`}>
                                            Bronze
                                            <input type="radio" name="aiRank" value="bronze" {...register('aiRank')} disabled={step === 2} />
                                        </label>
                                        <label className={`radio-button silver ${watch('aiRank') === 'silver' ? 'checked' : ''}`}>
                                            Silver
                                            <input type="radio" name="aiRank" value="silver" {...register('aiRank')} disabled={step === 2} />
                                        </label>
                                        <label className={`radio-button gold ${watch('aiRank') === 'gold' ? 'checked' : ''}`}>
                                            Gold
                                            <input type="radio" name="aiRank" value="gold" {...register('aiRank')} disabled={step === 2} />
                                        </label>
                                    </div>
                                    <TelegramText className={'white-opacity'}>Human made song with professional mastering</TelegramText>
                                </div>}
                                {step === 1 && <div className="form-item">
                                    <TelegramText className={'regularText'}><b><CurrencyDollar /> Token investment</b></TelegramText>
                                    <div className="form-control">
                                        <input className={errors.investment ? 'contol-error' : ''} type="number" placeholder="Enter investment" {...register('investment', { required: true })} disabled={step === 2} />
                                        <span className="currency">USD</span>
                                    </div>
                                    {errors.investment && <span className="error">Investment is required</span>}
                                </div>}
                                <div className="form-item">
                                    <TelegramText className={`regularText`}>
                                        <label htmlFor="lyrics"><b><ShortParagraph /> {step === 1 ? 'Prompt for the lyrics' : 'Update lyrics prompt (optionally)'}</b></label>
                                    </TelegramText>
                                    <textarea 
                                        id="lyrics" 
                                        className={`${errors.lyrics ? 'contol-error' : ''}`} 
                                        placeholder="Prompt or tags" 
                                        {...register('lyrics', { required: true, minLength: 10, maxLength: 500 })} 
                                    />
                                    {errors.lyrics && <span className="error">Lyrics must be between 10 and 500 characters</span>}
                                </div>
                                {step === 1 && <div className="form-item">
                                    <TelegramText className={'regularText'}><b><ImageIcon /> Project logo</b></TelegramText>
                                    <input type="file" id="file" {...register('file', {
                                        required: true,
                                        validate: {
                                            size: value => value[0]?.size <= 256 * 1024 * 1024 || 'File size must be less than 256MB',
                                            format: value => ['image/png', 'image/svg+xml'].includes(value[0]?.type) || 'File format must be PNG or SVG'
                                        }
                                    })} style={{ display: 'none' }} disabled={step === 2} />
                                    <label htmlFor="file" className={`file-input-label ${errors.file ? 'contol-error' : ''}`}>
                                        <FileIcon />
                                        {watch('file') && watch('file')[0] ? watch('file')[0].name : 'Select file'} {!watch('file') && <span>(PNG or SVG)</span>}
                                    </label>
                                    {errors.file && <span className="error">{errors.file.message}</span>}
                                </div>}
                                {step === 1 && (
                                    <div className="cta-buttons">
                                        <TelegramButton className={'block visible-mobile'} type="submit">Generate</TelegramButton>
                                        <TelegramButton className={'block outlined visible-mobile'} onClick={() => handleNavigate(PATH_CHARTS)}>Cancel</TelegramButton>
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="cta-buttons">
                                        <TelegramButton className={'block visible-mobile'} onClick={handlePublish}>Publish</TelegramButton>
                                        <TelegramButton className={'block outlined visible-mobile'} onClick={handleUpdate} disabled={updateCounter === 0}>Update ({updateCounter})</TelegramButton>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
                <MainNavigation currentPage={'create'} />
            </div>
            <audio ref={audioRef} />
            {tracks.map((track, index) => (
                <div key={index} onClick={() => handlePlayPause(track)}>
                    {track.title}
                </div>
            ))}
            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                autoClose={3000}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </TelegramScreen>
    );
};

export default CreatePage;