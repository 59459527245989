import React from "react";
import TelegramScreen from "../../components/kit/Screen/TelegramScreen";
import MainNavigation from "../../components/app/functions/MainNavigation";
import { ReactComponent as RedeemIcon } from '../../assets/redeem-icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/arrow-right-icon.svg';
import TokenIcon from '../../assets/token.png';
import TokenSmallIcon from '../../assets/token-small.png';


// import Wallet from "../../components/app/functions/walletAdapter";
import ConnectWallet from "../../components/app/functions/connectWallet";
import { useSprings, animated, useSpringRef } from "@react-spring/web";
//import Wallet from "../../components/app/functions/walletAdapter";
const Redeem = () => {

    const positions = [
        { top: '19px', left: '55px' },
        { top: '164px', left: '-57px' },
        { top: '65px', left: '314px' },
        { top: '328px', left: '216px' },
        // Add more positions as needed
    ];

    const [springs, api] = useSprings(
        positions.length,
        index => ({
            from: { transform: 'rotate(0deg)' },
            to: async (next) => {
                while (true) {
                    // await next({ transform: 'rotate(360deg)' });
                    // await next({ transform: 'rotate(0deg)' });
                    await next({ transform: 'rotate3d(0, 1, 0, 360deg)' });
                    await next({ transform: 'rotate3d(0, 1, 0, 0deg)' });
                }
            },
            config: { duration: 3000 }, // adjust the duration for the spin speed
        })
    );
    api.start();


    return (
        <TelegramScreen
            className={`main-wrapper--inactive main-wrapper`} id='gesuredZone'>
            <div className='main-container' style={{ backgroundColor: '#fff' }}>
                {springs.map((styles, index) => (
                    <animated.div
                        key={index}
                        style={{
                            ...styles,
                            position: 'absolute',
                            width: '92px',
                            height: '92px',
                            background: `url(${TokenSmallIcon})`,
                            borderRadius: '50%',
                            ...positions[index], // Apply the position from the array
                        }}
                    />
                ))}
                <div className={'token-block'}>
                    <img src={TokenIcon} />

                </div>
                <div className={'redeem-cards-container'}>


                    <div className={"gap-for-picklezone-block"}>
                        <p className={'text-bold-roboto'}>Redeem your tokens</p>
                        <p className={'text-title-roboto'}>To withdraw your tokens, at first, you need to connect your crypto wallet on Solana Network.
                            To do this, click on the button below, and then continue on our website or return to the app.</p>
                    </div>

                    <div className={'cards__redeem-button disabled'} >
                        <ConnectWallet />


                    </div>
                </div>
                <div className='forms__bottom'>
                    <MainNavigation currentPage={'redeem'} />
                </div>
            </div>
        </TelegramScreen>)

}
export default Redeem;
